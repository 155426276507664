import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeader from "../../components/PageHeader"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Testimonial from "../../components/Testimonial"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import {
  animeFadeUp,
  animeSlideOutLeft,
  animeSlideOutBot,
  animeFadeIn
} from "../../utils/Animations"
import Clients from "../../components/Clients"

const ServiceBarPage = ({data, transitionStatus}) => {
  const testimonials = [
    {
      name: 'Léa Boun, Brand Communication Specialist - Pernod Ricard France',
      text: 'Pleinement conquis par une première opération avec Belladrinks, nous avons mis en place un partenariat pour des opérations de dégustations au niveau national qui fut une totale réussite.'
    },
    {
      name: 'Sébastien Brivet, Responsable régional CHR - La Martiniquaise',
      text: 'Nous faisons appel à Belladrinks pour des opérations sur le terrain. C\'est une équipe très professionnelle, bien organisé et qui s’adapte à vos besoins.'
    },
    {
      name: 'Justine Maillard, Chargée de projets - Mondial Events',
      text: 'Super prestation bar à cocktail. L’agencement du bar est très sympa et le personnel est souriant, disponible et discret.'
    },
    {
      name: 'Tristam Mercier, Chargé de communication - LR Health & Beauty Systems',
      text: 'Prestation bar à cocktail et création de cocktails. Très professionnel et service de qualité, nous avons eu de très nombreux retours positifs de la part de nos participants.'
    }
  ]
  return (
    <Layout>
      <SEO
        title="Bar à cocktails événementiel - Belladrinks bar à cocktails événementiel"
        description="Service de bar à cocktails pour vos événements privés et professionnels jusqu'à 1500 personnes. Animez votre événement avec notre service de bar à cocktails événementiel."
      />
      <PageHeader data={data} transitionStatus={transitionStatus} title={"Bar à cocktails événementiel"}/>
      <section className="py-16 md:py-40 2xl:py-56 2xl:pb-48">
        <InView triggerOnce={true} threshold={0.5} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
              className="main-container grid grid-cols-1 xl:grid-cols-2 lg:gap-4" ref={ref}
            >
              <div>
                <motion.h2 variants={animeFadeUp} className="display-1 mb-8 pr-10">Dynamisez vos événements avec un bar à cocktails éphémère.</motion.h2>
                <motion.div
                  variants={{
                    hidden:{
                      width: 0
                    },
                    visible: {
                      width: '7rem',
                      transition: {
                        ease: "easeOut",
                        duration: "0.6"
                      }
                    }
                  }}
                  className="border-b-4 border-copper mt-2 mb-8"
                />
              </div>
              <div>
                <motion.p variants={animeFadeUp} className="lead">
                  Notre service de bar événementiel est régulièrement engagé pour fournir des services de bar aux agences événementielles, aux entreprises et à des clients privés exigeants.
                  Lorsque vous travaillez avec nous, vous bénéficiez d’un chargé de projet expérimenté, vous avez accès aux meilleurs produits et au meilleur personnel du secteur.
                  Notre philosophie est de faire tout ce que nous pouvons pour assurer une qualité de service du plus haut niveau.
                </motion.p>
              </div>
            </motion.div>
          )}
        </InView>
      </section>
      <section className="main-container pb-40">
        <div className="grid grid-cols-2 gap-2 lg:grid-rows-2 lg:grid-flow-row lg:auto-rows-fr">
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto lg:row-span-2 overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img1.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img2.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={{
                  visible: {
                    transition: { staggerChildren: 0.4 }
                  }
                }}
                className="bg-white py-10 px-8 md:p-16 md:py-20 2xl:p-20 2xl:py-32 col-span-2 lg:col-auto"
              >
                <motion.h2 variants={animeFadeUp} className="display-2 mb-6">Service sur-mesure</motion.h2>
                <motion.p variants={animeFadeUp} className="lead">
                  Belladrinks vous propose un service de bar à cocktails éphémère pour vos événements privés et professionels jusqu'à 1500 personnes. Du choix des cocktails à l'ambiance du bar nos chargés de projets répondent à toutes vos exigeances.
                </motion.p>
              </motion.div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto overflow-hidden col-span-2 ">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img3.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={{
                  visible: {
                    transition: { staggerChildren: 0.4 }
                  }
                }}
                className="bg-white py-10 px-8 md:p-16 md:py-20 2xl:p-20 2xl:py-32 col-span-2 lg:col-auto"
              >
                <motion.h2 variants={animeFadeUp} className="display-2 mb-6">Toujours plus engagé</motion.h2>
                <motion.p variants={animeFadeUp} className="lead">
                  Depuis 2016, nous utilisons des pailles écologiques obtenu grâce à de l'amidon de maïs. Nous favorisons les circuits courts ainsi que les produits de saisons et transformons un maximum de matières premières dans nos locaux.
                </motion.p>
              </motion.div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 col-span-2 lg:col-auto lg:h-auto overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutBot} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img4.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="flex justify-end mt-20">
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                initial={false}
                animate={inView ? "visible" : "hidden"}
                variants={animeFadeUp}
              >
                <TransitionLink
                  className="link"
                  to="/contact/"
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  Demandez un devis
                </TransitionLink>
              </motion.div>
            )}
          </InView>

        </div>
      </section>
      <section className="relative py-20 md:py-36 lg:py-40 2xl:py-56 bg-bigstone-light">
        <InView triggerOnce={true} threshold={0.25} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              ref={ref}
              className="main-container"
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
            >
              <motion.h3 variants={animeFadeUp} className="display-1 mb-8 text-white">Ils nous font confiance</motion.h3>
              <motion.div variants={animeFadeIn}>
                <Testimonial testimonials={testimonials}/>
              </motion.div>
              <Clients/>
            </motion.div>
          )}
        </InView>
      </section>
      <section className="py-20 md:py-36 lg:py-40 2xl:py-52">
        <InView triggerOnce={true} threshold={0.25} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              ref={ref}
              animate={inView ? "visible" : "hidden"}
              className="main-container text-center"
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.2 }
                }
              }}
            >
              <motion.h2 variants={animeFadeIn} className="display-1">Vous avez un projet ?</motion.h2>
              <motion.p variants={animeFadeIn} className="lead mb-8">Contactez notre équipe et obtenez un devis rapidement.</motion.p>
              <motion.div variants={animeFadeIn}>
                <TransitionLink
                  className="button bg-copper text-white hover:bg-white hover:text-copper mr-2 md:mr-8"
                  to="/contact/"
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  Contactez-nous
                </TransitionLink>
              </motion.div>
            </motion.div>
          )}
        </InView>
      </section>
    </Layout>
  )
}

export default ServiceBarPage

export const query = graphql`
    query {
        backgroundHeader: file(relativePath: { eq: "services/bar/closeup-barman-making-margaritas-with-five-glasses-set-line.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(relativePath: { eq: "services/bar/barman-making-sweet-cocktail-with-shaker-and-glass-on-the-desk.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(relativePath: { eq: "services/bar/HRC.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(relativePath: { eq: "services/bar/bartender-making-cocktails-in-club.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(relativePath: { eq: "services/bar/ralph-ravi-kayden-HRDcVywv7pE-unsplash.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`